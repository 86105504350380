import Stack, {
    onEntryChange,
  } from '@shared/contentstack/live-preview/live-preview';
import { HeroBannerSPZ } from '@src/cms-components';
import { useCallback, useEffect, useState } from 'react';
import { LPContainer } from '@shared/contentstack';

const AccordionListPreviewPage = (props: any) => {
const { pageContext } = props;

const [livePreviewData, setLivePreviewData] = useState<any>({});

Stack.startLivePreview(true);

const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
    contentTypeUid: 'hero_banner_spz',
    url:
        pageContext?.url && pageContext?.url !== '/'
        ? pageContext?.url
        : '/live-preview',
    })) as any;
    const result = response[0][0];
    setLivePreviewData(result);
}, [pageContext]);

useEffect(() => {
    onEntryChange(() => fetchData());
}, [fetchData]);

return (
    !!livePreviewData && (
    <LPContainer>
        <HeroBannerSPZ 
        {...{
        type: 'simple_banner',
        ...livePreviewData
        }
        } />
    </LPContainer>
    )
);
};

export default AccordionListPreviewPage;